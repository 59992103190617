export default{
    data(){
        return{
            afterList:[],
            shHeader:[
                '发货单号','零件名称','发货数量','状态','操作'
            ]
        }
    },
    created(){

    },
    methods:{
        userAfterOrderList:function(id){
            console.log(id)
            this.$post('/api/order/userAfterOrderList.html', {
                token:true,
            }).then((res) => {
                console.log(res.data)
                this. afterList = [];
                if (res.code == 200) {
                    let afterList = this.afterList;
                   
                    this.afterList = afterList.concat(res.data);
                    console.log(  this.afterList)
                    this.fid = id;
                }else{
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        toafterDetail:function(order_id){
            // this.$router.push({name:'SaleDetail',params:{id:order_id}})
            this.$router.push({ path: "/sale-detail", query: { id: order_id} });
        },
        viewRefused:function(id){
            this.$post('/api/order/viewRefused.html', {
                token:true,
                id:id
            }).then((res) => {
                if (res.code == 200) {
                    console.log(res.data.refused_content )
                    this.$alert(res.data.refused_content  || '未填写原因，请联系客服', {
                        confirmButtonText: '确定',
                        // callback: action => {
                          
                        // }
                      });
                }else{
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        }
    }
}