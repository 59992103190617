export default{
    data(){
        return{
            fid:1,
            index:0,
            status:[
                {
                    text:'全部',
                    id:1,
                    state:999
                },{
                    text:'待审核',
                    id:2,
                    state:0
                },{
                    text:'待报价',
                    id:3,
                    state:1
                },{
                    text:'待付款',
                    id:4,
                    state:2
                },{
                    text:'待收货',
                    id:5,
                    state:3
                },{
                    text:'已收货',
                    id:6,
                    state:4
                },{
                    text:'已完成',
                    id:7,
                    state:5
                },{
                    text:'售后订单',
                    id:8,
                    state:6
                },{
                    text:'已取消',
                    id:9,
                    state:99
                }
            ],
            theader:['订单号','加工分类','图纸','金额','状态','操作']
        }
    },
    created(){
       
    },
    methods: {
        selNavItem:function(state,id){
           this.page = 1;
            if(state == 6){
                this.userAfterOrderList(id);
            }else{
                this.getOrderLists(state,id)
            }
    
        }
    }
}