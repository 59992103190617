export default{
    data: function () {
        return {
            orderLists:[],
            order_amonut:'',
            order_id:'',
            order_num:'',
            order_name:'',
            show:false,
            popType:4,
            state:999,
            shType:'1',
            orderSn:'',
            jgType:'',
            ljHeader:[
                '发货单号',
                '零件名称',
                '数量',
                '物流名称',
                '物流单号',
                '操作',
            ],
            page:1,
            count:0,
            sub:0
        }
    },
    watch: {
        $route: {
            handler: function(val, oldVal){
                if(val.query && !val.query.sub) {
                    this.sub = 0
                    this.getOrderLists(999,1)
                }
            }
        }
    },
    created(){
        let query = this.$route.query;
        this.sub = query.sub?query.sub:0
        console.log(this.sub)
        this.getOrderLists(999,1)
    },
    methods: {
        getOrderLists: function (status,id) {

            this.$post('/api/order/orderLists.html', {
                token:true,
                sub:this.sub,
                status:status,
                page:this.page,
                pageSize:10
            }).then((res) => {
                console.log(res)
                this.orderLists  = [];
                if (res.code == 200) {
                    this.count = res.count;
                    let orderLists = this.orderLists;
                    console.log(orderLists)

                    this.orderLists  =orderLists.concat(res.data || [])    
                    this.fid = id;   
                }else{
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        openEvaluate:function(order_id,order_num){
            this.order_id = order_id +'';
            this.order_num = order_num +'';
            this.show = true;
            this.popType = 4;
        },
        openPay:function(order_id){
            this.order_id = order_id +'';
            this.show = true;
            this.popType = 1;
        },
        openBalance:function(order_id){
            this.order_id = order_id +'';
            this.show = true;
            this.popType = 2;
        },
        oepnAfterSales:function(order_id){
            this.order_id = order_id +'';
            this.show = true;
            this.popType = 3;
        },
        closepop:function(){
            this.show = false
            
            this.getOrderLists(this.state,this.fid);
            this.userAfterOrderList(this.fid);
        },
        oepnWl:function(order_id,shType,jgType,orderSn){
            console.log(orderSn)
            this.show = true;
            this.order_id = order_id +'';
            this.popType = 5;
            this.shType =shType + '';
            this.orderSn = orderSn +'';
            this.jgType = jgType+'';
        },
        imgConfirmGoods:function(id){
            this.$post('/api/order/imgConfirmGoods.html', {
                token:true,
                id:id,
            }).then((res) => {
                console.log(res)
                if (res.code == 200) {
                this.getOrderLists(this.state,this.fid)
                }else{
                   
                }
                return this.$message(res.msg);
            }).finally(() => {

            })
        },
     
    },
}