<template>
  <div class="order">
    <div class="h6" v-if="sub == 0">我的订单</div>
    <div class="h6" v-if="sub != 0">子账号订单</div>
    <div class="content">
      <ul class="order-nav cf">
        <li
          :class="['nav-item ', 'fl', fid == item.id ? 'on' : '']"
          v-for="(item, index) in status"
          :key="index"
          @click="selNavItem(item.state, item.id)"
        >
          {{ item.text }}
        </li>
      </ul>
      <div class="table-box" v-if="fid != 8">
        <ul class="t-header cf">
          <li class="t-width fl" v-for="(item, index) in theader" :key="index">
            {{ item }}
          </li>
        </ul>
        <div v-if="orderLists.length != 0">
          <div
            class="order-item"
            v-for="(item, index) in orderLists"
            :key="index"
          >
            <div class="order-header cf">
              <div class="l-box fl">{{ item.ctime }}</div>
              <div class="r-box fr" @click="toDetail(item.id)">订单详情></div>
              <div
                class="fr down-bj"
                v-if="item.status == 2"
                @click="downloadOffer(item.id)"
              >
                下载报价单
              </div>
            </div>
            <ul class="order-content cf">
              <li class="t-width fl">{{ item.order_sn }}</li>
              <li class="t-width fl">{{ item.category_name }}</li>
              <li class="t-width fl">{{ item.imgs_count }}</li>
              <li class="t-width fl">
                {{
                  item.status == 0 || item.status == 1
                    ? "评估中"
                    : item.order_amonut
                }}
              </li>
              <li
                class="t-width fl"
                v-for="(i, index) in status"
                :key="index"
                v-if="i.state == item.status"
              >
                {{ i.text }}
              </li>
              <li class="t-width fl cf" v-if="item.status != 99">
                <button
                  class="btn fr"
                  @click="changeDrawing(item.id)"
                  v-if="item.status == 0"
                >
                  <span>修改图纸</span>
                </button>
                <button
                  class="btn pay-btn fr"
                  @click="openPay(item.id)"
                  v-if="item.status == 2 && item.pay_status == 0 && item.is_offline_pay == 0 && item.is_refuse !== 1"
                >
                  <span>立即付款</span>
                </button>
                  <span v-if="item.status == 2 && item.pay_status == 0 && item.is_offline_pay == 0 && item.is_refuse == 1">拒绝报价</span>
                <button
                        class="btn wk-btn fr"
                        @click="refuseOrder(item.id)"
                        v-if="item.status == 2 && item.pay_status == 0 && item.is_offline_pay == 0 && item.is_refuse !== 1"
                >
                  <span>拒绝报价</span>
                </button>


                <p
                  class="fr"
                  style="color: #666"
                  v-if="item.is_offline_pay == 1"
                >
                  财务确认中
                </p>
                <button
                  class="btn wk-btn fr"
                  @click="openBalance(item.id)"
                  v-if="
                    item.pay_status == 2 &&
                    item.is_offline_pay == 0
                  "
                >
                  <span>支付尾款</span>
                </button>
                <button
                  class="btn wk-btn fr"
                  @click="openEvaluate(item.id, item.order_sn)"
                  v-if="item.status == 4 || (item.pay_status == 1 && item.status == 3)"
                >
                  <span>填写评价</span>
                </button>
                <button
                  class="btn fr"
                  @click="cancelOrder(item.id)"
                  v-if="item.status == 0 || item.status == 1"
                >
                  <span>取消订单</span>
                </button>
                <button
                        class="btn fr"
                        @click="openBill(item)"
                        v-if="item.pay_status == 1 && item.is_fp == 0"
                >
                  <span>发票申请</span>
                </button>
              </li>
            </ul>
            <div class="lj-box" v-if="item.status == 3 || item.status == 6">
              <ul class="lj-header cf">
                <li
                  class="fl lj-width"
                  v-for="(item, index) in ljHeader"
                  :key="index"
                >
                  {{ item }}
                </li>
              </ul>
              <div v-if="item.child_orders.length != 0">
                <ul
                  class="lj-bitem cf"
                  v-for="(i, index) in item.child_orders"
                  :key="index"
                >
                  <li class="lj-width fl">{{ i.invoice_sn }}</li>
                  <li class="lj-width fl">{{ i.name }}</li>
                  <li class="lj-width fl">{{ i.order_nums }}件</li>
                  <li class="lj-width fl">
                    {{ i.courier_company || "暂无数据" }}
                  </li>
                  <li class="lj-width fl">{{ i.courier_num || "暂无数据" }}</li>
                  <li class="lj-width fl">
                    <!-- <button class="lj-btn gray" >已收货</button> -->
                    <span class="sh-hint" v-if="i.status == 5">{{
                      i.after_type == 1 ? "同意返厂维修" : "同意退货退款"
                    }}</span>
                    <button
                      v-if="i.status == 1 || i.status == 6"
                      class="lj-btn org"
                      @click="imgConfirmGoods(i.id)"
                    >
                      确认收货
                    </button>
                    <p class="sh-hint" v-if="i.status == 2">已收货</p>
                    <p v-if="i.status == 3" class="sh-hint">售后申请中</p>
                    <button
                      v-if="i.status == 1"
                      class="lj-btn"
                      @click="oepnAfterSales(i.id)"
                    >
                      申请售后
                    </button>
                    <button
                      v-if="i.status == 6"
                      class="jj-btn"
                      @click="viewRefused(i.id)"
                    >
                      查看原因
                    </button>
                    <button
                      v-if="i.status == 4"
                      class="wl-btn"
                      @click="
                        oepnWl(
                          i.id,
                          i.after_type,
                          item.category_name,
                          i.invoice_sn
                        )
                      "
                    >
                      填写发货物流信息
                    </button>
                  </li>
                </ul>
              </div>
              <div v-else class="nofh-text">
                努力生产中，生产后第一时间给您发货
              </div>
            </div>
          </div>
        </div>
        <div class="nodata" v-else>暂无数据</div>
      </div>
      <div class="table-box" v-else>
        <ul class="sh-header cf">
          <li class="fl t-width" v-for="(item, index) in shHeader" :key="index">
            {{ item }}
          </li>
        </ul>
        <div v-if="afterList.length != 0">
          <div
            class="order-item"
            v-for="(item, index) in afterList"
            :key="index"
          >
            <div class="order-header cf">
              <div class="l-box fl">{{ item.ctime }}</div>
              <div class="r-box fr" @click="toafterDetail(item.id)">
                订单详情>
              </div>
            </div>
            <ul class="order-content cf sh">
              <li class="t-width fl">{{ item.invoice_sn || "暂无数据" }}</li>
              <li class="t-width fl">{{ item.name || "暂无数据" }}</li>
              <li class="t-width fl">{{ item.order_nums || "暂无数据" }}件</li>
              <li class="t-width fl">
                {{
                  item.status == 3
                    ? "售后处理中"
                    : item.status == 4
                    ? item.after_type == 1
                      ? "返厂维修中"
                      : "同意退货退款"
                    : item.status == 6
                    ? "售后拒绝"
                    : "售后中"
                }}
              </li>

              <li class="t-width fl cf">
                <button
                  class="btn wk-btn fr"
                  @click="viewRefused(item.id)"
                  v-if="item.status == 6"
                >
                  <span>查看原因</span>
                </button>
                <button
                  class="btn fr"
                  @click="cancelOrder(item.id)"
                  v-if="item.status == 0 || item.status == 1"
                >
                  <span>删除订单</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="nodata" v-else>暂无数据</div>
      </div>
      <el-pagination
        v-if="count > 10"
        style="margin-top: 20px"
        layout="prev, pager, next"
        :total="count"
        @current-change = "currentchange"
        @prev-click="prevclick"
        @next-click="nextclick"
      >
      </el-pagination>
    </div>
    <pay
      :show="show"
      :type="popType"
      :orderId="order_id"
      @closepop="closepop"
    ></pay>
    <balance
      :show="show"
      :type="popType"
      :orderId="order_id"
      @closepop="closepop"
    ></balance>
    <afterSale
      :show="show"
      :type="popType"
      :orderId="order_id"
      @closepop="closepop"
    ></afterSale>
    <evaluate
      :show="show"
      :type="popType"
      :orderId="order_id"
      :orderNum="order_num"
      @closepop="closepop"
    ></evaluate>
    <!-- <reason></reason> -->
    <saletype
      :show="show"
      :type="popType"
      :orderId="order_id"
      :shType="shType"
      :jgType="jgType"
      :orderSn="orderSn"
      @closepop="closepop"
    ></saletype>

    <!--报价 start-->
    <el-dialog title="" :visible.sync="openBillShow">
      <el-form  label-width="80px">
        <label class="user-text" style="margin-top: -20px;">申请开票</label>
        <div class="form-text-top">
          <el-row style="width: 100%;height: .9rem;">
            <el-col :span="12">
              <el-form-item label="订单编号:">
                <label>{{order_sn}}</label>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开票金额:">
                <label style="color: red;">￥{{pay_amonut}}</label>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="form-text-top">
          <el-row style="width: 100%;height: .9rem;">
            <el-col :span="12">
              <el-form-item label="发票类型:">
                <template>
                  <el-select v-model="fpType" style="width: 100%;" placeholder="请选择">
                    <el-option
                            v-for="item in fptext"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="抬头类型:">
                <el-radio v-model="ristype" :label="1">个人或事业单位</el-radio>
                <el-radio v-model="ristype" :label="2">企业</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="form-text-top">
          <el-row style="width: 100%;height: .9rem;">
            <el-col :span="12">
              <el-form-item label="发票抬头:">
                <el-input v-model="fpname" placeholder="填写需要开具发票的名称" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ristype == 2">
              <el-form-item label="税号:">
                <el-input v-model="disnum" placeholder="填写纳税人识别号" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="form-text-top" v-if="ristype == 2">
          <el-row style="width: 100%;height: .9rem;">
            <el-col :span="12">
              <el-form-item label="开户银行:">
                <el-input v-model="fpbank" placeholder="选填" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="银行账号:">
                <el-input v-model="fpaccount" placeholder="选填" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="form-text-top" v-if="ristype == 2">
          <el-row style="width: 100%;height: .9rem;">
            <el-col :span="12">
              <el-form-item label="企业地址:">
                <el-input v-model="fpaddress" placeholder="选填" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="企业电话:">
                <el-input v-model="fpmobile" placeholder="选填" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <label class="user-text">收票地址</label>
        <div class="form-text-top" style="margin-top: 20px;">
          <el-row style="width: 100%;height: .9rem;">
            <el-col :span="12">
              <el-form-item label="收票人:">
                <el-input v-model="spusername" placeholder="填写收票人名称" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话:">
                <el-input v-model="spmobile" placeholder="填写联系电话" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="form-text">
          <el-row>
            <el-col>
              <el-form-item label="收票地址:">
                <el-input v-model="spaddress" placeholder="填写收票地址" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="form-text">
          <el-row>
            <el-col>
              <el-form-item label="是否默认:">
                <el-radio v-model="is_default" :label="1">是</el-radio>
                <el-radio v-model="is_default" :label="0">否</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="user-button"  type="primary" :disabled="disabledOff" @click="applyFp()">提交申请</el-button>
        <el-button class="user-button"  type="error" @click="noapplyFp()">不开发票</el-button>

      </div>
    </el-dialog>
  </div>
</template>
<script>
import mixins from "../mixins";
import pay from "@/component/popup/pay/src/index";
import balance from "@/component/popup/balance/src/index";
import afterSale from "@/component/popup/after-sale/src/index";
import evaluate from "@/component/popup/evaluate/src/index";
import reason from "@/component/popup/reason/src/index";
import saletype from "@/component/popup/sale-type/src/index";
export default {
  components: {
    pay,
    balance,
    afterSale,
    evaluate,
    reason,
    saletype,
  },
  mixins,
};
</script>
<style scoped>
@import "../style.css";
</style>